import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";

function mediaQueryDesktop() {
  return window.matchMedia(`(min-width: 1280px)`);
}

function mediaQueryTablet() {
  return window.matchMedia(`(min-width: 600px)`);
}

enum Breakpoint {
  DESKTOP,
  TABLET,
  MOBILE,
}

export function getBreakpoint(): Breakpoint {
  if (mediaQueryDesktop().matches) {
    return Breakpoint.DESKTOP;
  }

  if (mediaQueryTablet().matches) {
    return Breakpoint.TABLET;
  }

  return Breakpoint.MOBILE;
}

type UseBreakpoint = {
  breakpoint: Breakpoint;
  isMobile: boolean;
  isDesktop: boolean;
  isTablet: boolean;
};

const useBreakpoint = (): UseBreakpoint => {
  const [breakpoint, setBreakpoint] = useState(getBreakpoint());
  const isMobile = breakpoint === Breakpoint.MOBILE;
  const isDesktop = breakpoint === Breakpoint.DESKTOP;
  const isTablet = breakpoint === Breakpoint.TABLET;
  useLayoutEffect(() => {
    function updateSize() {
      setBreakpoint(getBreakpoint());
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return { breakpoint, isMobile, isDesktop, isTablet };
};

type UseHandleKeyUp = [boolean, (val: boolean) => void];

function useHandleKeyUp(): UseHandleKeyUp {
  const [state, setState] = useState(false);

  const handleKeyUp = useCallback(() => {
    setState(false);
  }, [setState]);

  useEffect(() => {
    if (state) {
      window.addEventListener("click", handleKeyUp);
    } else {
      window.removeEventListener("click", handleKeyUp);
    }

    return () => window.removeEventListener("click", handleKeyUp);
  }, [state]);

  return [state, setState];
}

function useQueryParams(): URLSearchParams {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

type UrlTabsChanger = {
  tab: number;
  handleChange(
    event: React.ChangeEvent<unknown>,
    newValue: number,
    providedQueryParams?: URLSearchParams
  ): void;
};

export const useUrlTabs = (name: string): UrlTabsChanger => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const location = useLocation();
  const tab = parseInt(queryParams.get(name) ?? "0");

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
    providedQueryParams?: URLSearchParams
  ) => {
    const queryParamsInstance = providedQueryParams ?? queryParams;

    queryParamsInstance.set(name, newValue.toString());
    history.push({
      pathname: location.pathname,
      search: queryParamsInstance.toString(),
    });
  };

  return { tab, handleChange };
};

export { useBreakpoint, useHandleKeyUp, useQueryParams };
