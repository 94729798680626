/* eslint-disable no-restricted-syntax */
export const colors = {
  // warn
  warnAlertBg: "#fcf4e7",
  warnAlertBg2: "#fcf4e7b3",
  warnAlertBg3: "#eb8d00",
  warnAlertText: "#4c4c4c",
  warnAlertText2: "#77541f",
  warningAlertBg: "#fef7ed",
  // success
  successAlertText: "#008c5b",
  successAlertBg: "#eef9f6",
  successAlertBg2: "#eaf7f2b3",
  successAlertBg3: "#00a76d",
  // info
  infoAlertText: "#043d66",
  infoAlertBg: "#eef4f7",
  infoAlertBg2: "#e9eff4b3",
  // danger
  dangerAlertBg: "#feefef",
  dangerAlertBg2: "#f9e8e8b3",
  dangerAlertText: "#c80400",
  // gray
  gray1: "#131313",
  gray2: "#4c4c4c",
  gray3: "#999999",
  gray4: "#c2c2c2",
  gray5: "#d9d9d9",
  gray6: "#ebebeb",
  gray7: "#f2f2f2",
  gray8: "#fbfbfb",
  gray9: "#f0f0f0",
  gray10: "#c5c5c5",
  gray11: "#cccccc",
  gray12: "#f5f8fa",
  gray13: "#ffffff00",
  gray14: "#dadce0",
  gray15: "#4c4c4c24",
  gray16: "#ededed",
  gray17: "#d8d9d9",
  //white
  white: "#ffffff",
  // blue
  blue1: "#054d80",
  blue2: "#e5edf2",
  blue3: "#8cacc6",
  blue4: "#e6edf2",
  blue5: "#dae0e6",
  blue6: "#377199",
  blue7: "#f0f4f7",
  blue8: "#d7e9f7",
  blue9: "#e8eff4",
  // purple
  purple1: "#ecedf6",
  purple2: "#d3d6f5",
  // yellow
  yellow1: "#ffedd1",
  yellow2: "#fcf4e7b3",
  // green
  green1: "#c9f2e0",
  green2: "#48c38f",
  // red
  red1: "#d23c3c",
};
