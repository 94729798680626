import React, { FC } from "react";
import styled, { keyframes } from "styled-components";
import { getStaticImage } from "../../utils/static";
import { flapOut } from "../../utils/z-index";

const SpinAnimmation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const LoaderContainer = styled.div<{ position?: string }>`
  position: ${(props) => props.position ?? "absolute"};
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.77);
  z-index: ${flapOut};
`;

const LoaderWrapper = styled.span`
  position: relative;
  display: inline-block;
  padding: 24px;
  width: 82px;
  height: 82px;
  box-sizing: border-box;
`;

const LoaderImage = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 34px;
`;

const LoaderBase = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  display: inline-block;
  border: 5px solid ${(props) => props.theme.colors.misc2};
  border-radius: 50%;
  box-sizing: border-box;
  animation: ${SpinAnimmation} 1.5s infinite;
  left: 0;
  top: 0;
`;

const LoaderBorder = styled.span`
  display: block;
  position: absolute;
  height: 100%;
  border: 5px solid ${(props) => props.theme.colors.primary1};
  width: 100%;
  left: -5px;
  top: -5px;
  border-radius: 50%;
  border-left-color: transparent;
  transform: rotate(-45deg);
`;

const LoaderBorderTopCorner = styled.span`
  position: absolute;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.primary1};
  top: 50%;
  left: -5px;
  transform: translateY(-50%);
`;

const LoaderBorderBottomCorner = styled.span`
  position: absolute;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.primary1};
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
`;

const ExtraSmallLoader = styled(LoaderWrapper)`
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  padding: 0;

  img {
    width: 15px;
  }
`;

const SmallLoader = styled(LoaderWrapper)`
  width: 50px;
  height: 50px;

  img {
    width: 25px;
  }
`;

const MediumLoader = styled(LoaderWrapper)`
  width: 82px;
  height: 82px;
`;

const LargeLoader = styled(LoaderWrapper)`
  width: 100px;
  height: 100px;
`;

const LoaderText = styled.p`
  color: ${(props) => props.theme.colors.grays1};
  text-align: center;
  font-size: 16px;
  line-height: 19px;
`;

export enum LoaderSize {
  extraSmall,
  small,
  medium,
  large,
}

interface LoaderProps {
  text?: string;
  size?: LoaderSize;
  className?: string;
  position?: "absolute" | "fixed" | "sticky" | "static";
}

const sizes: Record<LoaderSize, FC> = {
  [LoaderSize.extraSmall]: ExtraSmallLoader,
  [LoaderSize.small]: SmallLoader,
  [LoaderSize.medium]: MediumLoader,
  [LoaderSize.large]: LargeLoader,
};

const Loader = ({
  text = "",
  size = LoaderSize.medium,
  position,
  className,
}: LoaderProps): JSX.Element => {
  const Wrapper = sizes[size];
  return (
    <LoaderContainer className={className} position={position}>
      <Wrapper>
        <LoaderImage src={getStaticImage("loader.svg")} />
        <LoaderBase>
          <LoaderBorder />
          <LoaderBorderTopCorner />
          <LoaderBorderBottomCorner />
        </LoaderBase>
      </Wrapper>
      {text && <LoaderText>{text}</LoaderText>}
    </LoaderContainer>
  );
};

export default Loader;
