/* eslint-disable no-restricted-syntax */
import { DefaultTheme } from "styled-components";

const defaultTheme: DefaultTheme = {
  borderRadius: {
    small: "4px",
    medium: "12px",
    large: "50%",
  },

  transitions: {
    regular: "all 0.5s ease",
  },

  blur: {
    regular: "blur(3px)",
  },

  fonts: {
    normal: "Roboto, Helvetica, Arial, sans-serif",
    bold: "Roboto-Bold, Roboto, Helvetica, Arial, sans-serif",
    regular: "Roboto-Regular, Roboto, Helvetica, Arial, sans-serif",
  },

  colors: {
    primary1: "#004C80",
    primary2: "#043D66",
    primary3: "#033354",
    primary4: "#054D80",

    alerts1: "#EEF4F7",
    alerts2: "#F6F6F6",
    alerts3: "#FEF7ED",
    alerts4: "#EEF9F6",
    alerts5: "#FEEFEF",
    alerts6: "#FCEDD2",

    accent1: "#C80400",
    accent2: "#F05455",
    accent3: "#EB8D00",
    accent4: "#FFCC00",
    accent5: "#FCF4E7",
    accent6: "#77541F",
    accent7: "#00A76D",
    accent8: "#008C5B",
    accent9: "#C9EADF",
    accent10: "#F4CCCC",

    misc1: "#E3E6E8",
    misc2: "#E8EFF4",
    misc3: "#F5F8FA",
    misc4: "#8CACC6",
    misc5: "#CCDBE6",
    misc6: "#B3CCC4",

    grays1: "#131313",
    grays2: "#4C4C4C",
    grays3: "#999999",
    grays4: "#C2C2C2",
    grays5: "#D9D9D9",
    grays6: "#EBEBEB",
    grays7: "#F2F2F2",
    grays8: "#F2F2F2",
    grays9: "#FBFBFB",

    white: "#ffffff",
    black: "#000000",
  },
};

export { defaultTheme };
