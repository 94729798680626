/* eslint-disable no-restricted-syntax */
import { createTheme } from "@mui/material/styles";

export const globalTheme = createTheme({
  palette: {
    primary: { main: "#004C80" },
    secondary: { main: "#E3E6E8" },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        caption: {
          fontSize: "13px",
          fontFamily: "Roboto",
          fontWeight: 400,
        },
        body1: {
          fontSize: "15px",
          fontFamily: "Roboto",
          fontWeight: 400,
        },
        body2: {
          fontSize: "14px",
          fontFamily: "Roboto",
          fontWeight: 400,
        },
        subtitle1: {
          fontSize: "16px",
          fontFamily: "Roboto",
          fontWeight: 500,
        },
        subtitle2: {
          fontSize: "15px",
          fontFamily: "Roboto",
          fontWeight: 500,
        },
        h1: {
          fontWeight: 545,
          fontFamily: "Roboto",
          fontSize: "36px",
          color: "#131313",
        },
        h2: {
          fontWeight: 545,
          fontFamily: "Roboto",
          fontSize: "28px",
          color: "#131313",
        },
        h3: {
          fontWeight: 500,
          fontFamily: "Roboto",
          fontSize: "24px",
          color: "#131313",
        },
        h4: {
          fontWeight: 500,
          fontFamily: "Roboto",
          fontSize: "20px",
          color: "#131313",
        },
        h6: {
          fontWeight: 450,
          fontFamily: "Roboto",
          fontSize: "14px",
          color: "#131313",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        textPrimary: {
          color: "white",
        },
        textSecondary: {
          color: "white",
        },
        containedPrimary: {
          backgroundColor: "#054D80",
          "&:hover": {
            backgroundColor: "#043D66",
          },
        },
        containedSecondary: {
          color: "#054D80",
          backgroundColor: "white",
          border: "1px solid #054d80",
          "&:hover": {
            backgroundColor: "#F2F2F2",
          },
          "&:disabled": {
            border: "none",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          marginRight: 10,
          fontWeight: 500,
          color: "#054D80",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 0px 0px lightgrey",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          boxShadow: "none",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#E6EDF2",
          },
          "&:hover": {
            backgroundColor: "#E6EDF2",
          },
          fontFamily: "Roboto",
          fontWeight: 500,
          textTransform: "none",
          borderRadius: "4px",
          fontSize: "15px",
          height: "38px",
          color: "#054D80",
          marginRight: "10px",
        },
        textColorInherit: {
          opacity: 1,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&$completed": {
            color: "green",
          },
          "&$active": {
            color: "#054D80",
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: "#033354",
          height: "40px",
          "&.Mui-selected": {
            backgroundColor: "#D6E4EF",
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "#033354",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          width: "100%",
          height: "40px",
          fontSize: "15px",
          fontFamily: "Roboto",
          fontWeight: 400,
          "&:hover": {
            backgroundColor: "#F5F8FA",
            color: "#054D80",
          },
        },
        paper: { minHeight: "200px" },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          width: "100%",
          height: "40px",
          fontSize: "15px",
          fontFamily: "Roboto",
          fontWeight: 400,
          "&:hover": {
            backgroundColor: "#F5F8FA",
            color: "#054D80",
            cursor: "pointer",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: "308px",
          height: "40px",
          backgroundColor: "#FBFBFB",
          borderRadius: "4px",
          "&:hover": {
            backgroundColor: "#F5F8FA",
          },
        },
        sizeSmall: {
          width: "160px",
        },
      },
    },

    MuiSnackbar: {
      styleOverrides: {
        root: {
          position: "sticky",
          width: "100%",
          display: "inline-block",
        },
        anchorOriginBottomRight: {
          "@media (min-width: 600px)": {
            bottom: 0,
          },
          bottom: 0,
          justifyContent: "center",
        },
      },
    },
  },
});
