import React from "react";
import styled from "styled-components";
import Loader from "../loader/Loader";

const CenterInner = styled.div``;

const CenterOuter = styled.div`
  width: ${(props: Props) => props.minWidth || "100px"};
  height: ${(props: Props) => props.minHeight || "100px"};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  position: relative;
`;

type Props = {
  minHeight?: string;
  minWidth?: string;
};

const CenteredLoadingIndicator = (props: Props): React.ReactElement => {
  return (
    <CenterOuter {...props}>
      <CenterInner {...props}>
        <Loader />
      </CenterInner>
    </CenterOuter>
  );
};

export default CenteredLoadingIndicator;
