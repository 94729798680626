import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CenteredLoadingIndicator from "./product/global/loading/CenteredLoadingIndicator";
import { globalTheme } from "./product/config/themes";
import { ThemeProvider } from "@mui/material/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import GlobalStyle from "./product/config/global-styles";
import styled from "styled-components";
import { useBreakpoint } from "./product/utils/hooks";
import { defaultTheme } from "./default-theme";

const AppLoading = styled(CenteredLoadingIndicator)`
  margin: 0 auto;
`;

const AppStart = React.lazy(
  () =>
    import(
      /* webpackChunkName: "app-start" */ "./product/accounts/company/Start"
    )
);

const HomePage = React.lazy(
  () => import(/* webpackChunkName: "homepage" */ "./homepage/Homepage")
);
const App: React.FC = () => {
  const { isDesktop } = useBreakpoint();
  return (
    <ThemeProvider theme={globalTheme}>
      <StyledThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <Suspense
            fallback={
              <AppLoading
                minWidth={isDesktop ? "500px" : "100%"}
                minHeight={"500px"}
              />
            }
          >
            <Switch>
              <Route path={"/app"}>
                <AppStart />
              </Route>
              <Route path={"/"}>
                <HomePage />
              </Route>
            </Switch>
          </Suspense>
        </BrowserRouter>
        <GlobalStyle />
      </StyledThemeProvider>
    </ThemeProvider>
  );
};

export default App;
