import { createGlobalStyle } from "styled-components";
import { colors } from "../utils";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.fonts.normal};
    font-size: 15px;
    font-weight: 400;
    color: ${colors.gray1};
    margin: 0;

    @media (min-width: 1280px) {
      margin: 8px;
    }
  }

  h5 {
    margin: 0;
    font-family: ${(props) => props.theme.fonts.normal};
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
  }

  .text-center {
    text-align: center !important;
  }
  
  .mt-10px {
    margin-top: 10px !important;
  }

  .mt-20px {
    margin-top: 20px !important;
  }
  
  .mr-20px {
    margin-right: 20px !important;
  }
`;

export default GlobalStyle;
